.selectBox {
    box-sizing: border-box;
    border: none;
    height: 100%;
    width: 150px;
    padding: 0px 12px;
    padding-right: 12px;
    border-radius: 4px;
    font-size: 14px;
    color: #9B9C9F;
    background: #ffffff;
    height: 42px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.bannerSelectBox {
    box-sizing: border-box;
    border: none;
    height: 100%;
    width: 290px;
    padding: 0px 12px;
    padding-right: 12px;
    border-radius: 4px;
    font-size: 14px;
    color: #9B9C9F;
    background: #ffffff;
    height: 42px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.memberSelectBox {
    box-sizing: border-box;
    border: none;
    height: 100%;
    width: 290px;
    padding: 0px 12px;
    padding-right: 12px;
    border-radius: 4px;
    font-size: 14px;
    color: #9B9C9F;
    background: #ffffff;
    height: 42px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}


.member-cell .withdrawal {
    color:#AC0000;
}

.member-cell .w4withdrawal {
    color:#DC6060;
}

.member-cell .loading-dim {
    opacity: 0;
    display:none;
}
.member-cell.loading {
    opacity: 0.5;
}
.member-cell.loading .loading-dim {
    opacity: 0.8;
    display: block;
    animation: fade-in-opacity 2s normal;
}
.loading-dim {
    position: relative;
    /* left: 0; */
    /* right: 0; */
    /* top: 0; */
    /* bottom: 0; */
    height: 0;
    padding: 0;
    margin: 0;
    text-align: left;
}
.loading-img {
    margin-top:90px;
    animation: loadingRotation 0.5s infinite linear;
}
@keyframes loadingRotation {
    from {
        transform: rotate(0deg);
        margin-left:0%;
    }
    50% { margin-left:calc(100% - 192px) }
    to {
        transform: rotate(720deg);
        margin-left:0%;
    }
}
@keyframes fade-in-opacity {
    from { opacity: 0.0; }
    to   { opacity: 0.9; }
}

