.NewsSearch_Modal_Container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
}

.NewsSearch_Modal {
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.NewsSearch_Modal_check {
    margin: 0px 0px 24px;
    font-family: "NS bo";
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.NewsSearch_Modal_dal {
    width: 1100px;
    box-sizing: border-box;
    padding: 30px 20px 24px;
    border-radius: 5px;
    font-family: "NS re";
    font-size: 16px;
    overflow: auto;
    height: 800px;
}

.NewsSearch_Modal_height {
    height: 30px;
}

.NewsSearch_Modal_context {
    text-align: center;
}