.NovelRegist_Modal_Container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1200;
    position: fixed;
    top: 0;
    left: 0;
}

.NovelRegist_Modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
}

.NovelRegist_Modal_dal {
    width: 328px;
    box-sizing: border-box;
    padding: 30px 20px 24px;
    background: white;
    border-radius: 5px;
    font-family: "NS re";
    font-size: 16px;
    height: 190px;
}

.NovelRegist_Modal_title {
    margin: 0px 0px 24px;
    font-family: "NS bo";
    font-weight: 700;
    font-size: 16px;
    text-align: center;
}

.NovelRegist_Modal_close {
    margin-top: 140px;
    position: absolute;
    display: flex;
    width: 328px;
    justify-items: center;
    justify-content: space-around;
}

.NovelRegist_Modal_context {
    text-align: center;
}