.react-datepicker {
	font-size: 12px !important;
	padding: 10px;
}

.react-datepicker__triangle {
	position: absolute;
	left: 0px;
}

.react-datepicker__month-container {
	padding: 0;
	margin: 0;
}

.react-datepicker__month {
	margin: 0;
}

.react-datepicker__header {
	padding: 0 !important;
	border: none;
	background-color: #ffffff;
}

.react-datepicker__day-names {
	margin-bottom: 0;
}

.react-datepicker__day-name {
	margin: 0;
	font-size: 12px;
	width: 28px;
	height: 28px;
	margin-right: 2px;
}

.react-datepicker__day {
	margin: 0;
	font-size: 12px;
	width: 28px;
	height: 28px;
	border: 1px solid #ffffff;
}

.react-datepicker__day.react-datepicker__day--highlighted:hover {
	border: 0px;
	border-color: #ffffff;
}

.react-datepicker__day.react-datepicker__day {
	color: #000;
	background-color: white;
}

.react-datepicker__day.react-datepicker__day--today {
	font-weight: normal;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
	border-radius: 50%;
	background-color: #ED5255;
	color: white;
	margin: 0;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range {
	border-radius: 0;
	background-color: #fbdcdd;
	opacity: 30;
}

.react-datepicker__day.react-datepicker__day--in-range {
	border-radius: 0;
	background-color: #fbdcdd;
	opacity: 30;
}

.react-datepicker__day.sunday {
	color: #e21c23 !important;
}

.react-datepicker__day.sunday:hover {
	color: #ffffff !important;
}

.react-datepicker__day--disabled {
	color: #cccccc !important;
}