.Edit_Modal_Container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
}

.Edit_Modal {
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    width: 50%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
